import Layout from "@/layout/index.vue";
export default [
  {
    path: "/",
    redirect: "/home",
  },
  // 首页
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "home",
        name: "home",
        component: () => import("@/pages/home/index.vue"),
        meta: {
          nav: "/home",
          activeNav: "/home",
        },
      },
    ],
  },
  // 关于我们
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "about",
        name: "about",
        component: () => import("@/pages/about/index.vue"),
        meta: {
          nav: "/about",
          activeNav: "/about",
        },
      },
    ],
  },
  // 服务与产品
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "service",
        name: "service",
        component: () => import("@/pages/service/index.vue"),
        meta: {
          nav: "/service",
          activeNav: "/service",
        },
      },
    ],
  },
  // 产品下载
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "download",
        name: "download",
        component: () => import("@/pages/download/index.vue"),
        meta: {
          nav: "/download",
          activeNav: "/download",
        },
      },
    ],
  },
  // 新闻资讯
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "news",
        name: "news",
        component: () => import("@/pages/news/index.vue"),
        meta: {
          nav: "/news",
          activeNav: "/news",
        },
      },
    ],
  },
  // 新闻资讯详情
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "newsDetail",
        name: "newsDetail",
        component: () => import("@/pages/news/newsDetail.vue"),
        meta: {
          nav: "/newsDetail",
          activeNav: "/news",
        },
      },
    ],
  },
  // 联系我们
  {
    path: "/",
    component: Layout,
    children: [
      {
        path: "contact",
        name: "contact",
        component: () => import("@/pages/contact/index.vue"),
        meta: {
          nav: "/contact",
          activeNav: "/contact",
        },
      },
    ],
  },
  {
    path: "/404",
    component: () => import("@/pages/404/index.vue"),
  },
  {
    path: "*",
    redirect: "/404",
  },
];
