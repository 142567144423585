<template>
    <!--    <div class="layout-header">-->
    <nav :class="['navbar','navbar-expand-lg','fixed-top', (isScroll || isWhiteNav) && 'isScroll',isSpecial && 'isSpecial']">
        <a class="navbar-brand" href="#">
            <img :src="screenWidth<969?require('@/assets/image/logo2.png'):imgUrl" class="d-inline-block align-top"
                 alt="">
        </a>
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav"
                aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
            <svg-icon
                    iconClass="navToggle"
                    className="nav-toggle"
            />
        </button>
        <div class="collapse navbar-collapse" id="navbarNav">
            <ul class="layout-header-nav navbar-nav">
                <li :class="['layout-header-nav_item', activeNav === item.path && 'active']" @click="toPage(item)"
                    v-for="(item, index) in navList" :key="index">
                    {{ item.label }}
                </li>
            </ul>
            <!--      <div class="navbar-nav">
                      <a class="nav-link active" href="#">Home <span class="sr-only">(current)</span></a>
                      <a class="nav-link" href="#">Features</a>
                      <a class="nav-link" href="#">Pricing</a>
                      <a class="nav-link disabled">Disabled</a>
                  </div>-->
        </div>
    </nav>
    <!--    <img :src="imgUrl" alt=""/>
        <div class="layout-header-nav">
            <div
                    :class="['layout-header-nav_item', activeNav === item.path && 'active']"
                    v-for="(item, index) in navList"
                    :key="index"
                    @click="toPage(item)"
            >
                {{ item.label }}
            </div>
        </div>-->
    <!--    </div>-->
</template>

<script>
    export default {
        name: "layoutHeader",
        data() {
            return {
                screenWidth: null,
                // 导航列表
                navList: [
                    {
                        path: "/home",
                        label: "首页",
                    },
                    {
                        path: "/about",
                        label: "关于我们",
                    },
                    {
                        path: "/service",
                        label: "服务与产品",
                    },
                    {
                        path: "/download",
                        label: "产品下载",
                    },
                    {
                        path: "/news",
                        label: "新闻资讯",
                    },
                    {
                        path: "/contact",
                        label: "联系我们",
                    },
                ],
                // 当前导航
                activeNav: "",
                // 是否是滚动状态
                isScroll: false,
                // 是否是白色背景导航
                isWhiteNav: false,
                isSpecial: false,
            };
        },
        computed: {
            imgUrl() {
                let imgUrl = "";
                if (this.isScroll || this.isWhiteNav || this.isSpecial) {
                    imgUrl = require("@/assets/image/logo2.png");
                } else {
                    imgUrl = require("@/assets/image/logo1.png");
                }
                return imgUrl;
            },
        },
        watch: {
            $route: {
                handler(route) {
                    this.activeNav = route.meta.activeNav;
                    let name = ["/newsDetail"];
                    this.isWhiteNav = name.includes(route.meta.nav);
                    let special = ["/download"];
                    this.isSpecial = special.includes(route.meta.nav);
                    this.$nextTick(() => {
                        let dom = document.querySelector("#app");
                        dom.scrollTop = "0px";
                    });
                },
                immediate: true,
                deep: true,
            },
        },
        methods: {
            // 导航跳转
            toPage(item) {
                console.log(document.getElementsByClassName('navbar-toggler'))
                document.getElementsByClassName('navbar-toggler')[0].setAttribute('aria-expanded', 'false')
                document.getElementById('navbarNav').classList.remove('show')
                this.$router.push({
                    path: item.path,
                });
            },
            scrolling() {
                // 滚动条距文档顶部的距离
                let dom = document.querySelector("#app");
                let scrollTop = dom.scrollTop;
                if (scrollTop > 0) {
                    this.isScroll = true;
                } else {
                    this.isScroll = false;
                }
            },
        },
        mounted() {
            //获取屏幕长宽
            (this.screenWidth = document.documentElement.clientWidth),
                (window.onresize = () => {
                    //屏幕尺寸变化就重新赋值
                    return (() => {
                        this.screenWidth = document.documentElement.clientWidth;
                    })();
                });
            let dom = document.querySelector("#app");
            dom.addEventListener("scroll", this.scrolling);
        },
        beforeDestroy() {
            let dom = document.querySelector("#app");
            dom.removeEventListener("scroll", this.scrolling);
        },
    };
</script>

<style scoped lang="scss">
    .navbar {
        width: 100%;
        /*height: 100%;*/
        justify-content: space-around;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        padding-bottom: 0;

        .navbar-toggler {
            .nav-toggle {
                color: #ffffff;
                font-size: 1.4rem;
            }
        }

        &-collapse {
            flex-grow: initial;

            .layout-header-nav {
                cursor: pointer;
                white-space: nowrap;

                .layout-header-nav_item {
                    height: 78px;
                    line-height: 78px;
                    font-size: 16px;
                    margin-right: 48px;
                    color: rgba(255, 255, 255, 0.7);

                    &.active {
                        font-weight: bold;
                        color: #ffffff;
                        border-bottom: 2px solid #32d826;
                    }
                }

            }

        }
    }

    .isScroll {
        background-color: #ffffff;

        .navbar-toggler {
            .nav-toggle {
                color: #666666;
            }
        }

        .navbar-collapse {
            .layout-header-nav_item {
                color: #666666;

                &.active {
                    color: #333333;
                }
            }
        }
    }

    .isSpecial {
        .navbar-collapse {
            .layout-header-nav_item {
                color: #666666;

                &.active {
                    color: #333333;
                }
            }
        }
    }

    @media only screen and (max-width: 969px) {
        .navbar {
            background-color: #fff;
            padding: 0.5rem 0;

            &-brand {
                img {
                    max-width: 8rem;
                }
            }

            .navbar-toggler {
                .nav-toggle {
                    color: #666666;
                }
            }

            &-collapse {
                .layout-header-nav {
                    padding: 0 0.5rem;

                    .layout-header-nav_item {
                        margin: 0;
                        height: auto;
                        line-height: 1;
                        padding: 1.2rem 0;
                        color: #666666;
                        font-size: 1.2rem;

                        &.active {
                            font-weight: bold;
                            color: #333333;
                            border-bottom: none;
                        }
                    }
                }

            }
        }
    }
</style>
