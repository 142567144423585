import VueRouter from "vue-router";
import Vue from "vue";
import routes from "./routes";

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};
Vue.use(VueRouter);

let mode = "hash";
if (process.env.NODE_ENV === "production") {
  mode = "history";
}

const router = new VueRouter({
  routes,
  mode,
  base: "/hy",
  scrollBehavior: () => ({ y: 0 }), //滚动到顶端
});

export default router;
