import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
/*滚动加载列表*/
import infiniteScroll from "vue-infinite-scroll";
// svg
import "./assets/icons/index.js";
import "./assets/css/media.scss"
Vue.use(infiniteScroll);
Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
