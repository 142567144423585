<template>
    <div class="layout-footer">
        <img src="@/assets/image/logo3.png" alt="" class="layout-footer-logo"/>
        <div class="layout-footer-container">
            <div class="layout-footer-left">
                <div class="layout-footer-left_name">奇点海洋科技（河南）有限公司</div>
                <div class="layout-footer-left_tel">咨询热线：0371-67215566</div>
                <div class="layout-footer-left_address">
                    公司地址：河南省郑州市郑东新区平安大道正商博雅广场1号楼11层1102号
                </div>
                <div class="layout-footer-icons">
                    <img src="@/assets/image/baidu.png" alt=""/>
                    <img src="@/assets/image/dianxin.png" alt=""/>
                    <img src="@/assets/image/icon3.png" alt=""/>
                    <img src="@/assets/image/starsoft.png" alt=""/>
                    <img src="@/assets/image/icon5.png" alt=""/>
                    <img src="@/assets/image/icon6.png" alt=""/>
                </div>
            </div>
            <div class="layout-footer-right">
                <div class="layout-footer-right_item">
                    <img src="@/assets/image/mp.png" alt=""/>
                    <div>微信公众号</div>
                </div>
                <div class="layout-footer-right_item">
                    <img src="@/assets/image/microblog.jpg" alt=""/>
                    <div>官方微博</div>
                </div>
            </div>
        </div>

        <div class="layout-footer-copy">豫ICP备2023014603号</div>
    </div>
</template>

<script>
    export default {};
</script>

<style scoped lang="scss">

    .layout {
        &-footer {
            height: 480px;
            padding: 69px 18.75% 0;
            background-color: #051b34;
            position: relative;

            &-container {
                display: flex;
                justify-content: space-between;
                /*margin-bottom: 46px;*/
            }

            &-left {
                width: 59%;

                &_name {
                    font-size: 20px;
                    color: #ffffff;
                    margin-bottom: 46px;
                }

                &_tel,
                &_address {
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.7);
                    margin-bottom: 16px;
                }

                &_address {
                    margin-bottom: 46px;
                }
            }

            &-right {
                display: flex;

                &_item {
                    font-size: 14px;
                    color: rgba(255, 255, 255, 0.7);
                    text-align: center;

                    &:nth-of-type(1) {
                        margin-right: 20px;
                    }

                    img {
                        width: 140px;
                        margin-bottom: 16px;
                    }
                }
            }

            &-icons {
                display: flex;
                align-items: center;

                img {

                    margin-right: 32px;
                }
            }

            &-copy {
                width: 100%;
                height: 48px;
                line-height: 47px;
                border-top: 1px solid rgba(255, 255, 255, 0.1);
                position: absolute;
                left: 0;
                bottom: 0;
                text-align: center;
                font-size: 12px;
                color: rgba(255, 255, 255, 0.3);
            }
        }
    }

    @media only screen and (max-width: 969px) {
        .layout {
            &-footer {
                height: auto;
                padding:0.7rem 0.5rem;

                &-container {
                    margin-bottom: 2rem;
                }

                &-logo {
                    width: 9rem;
                }

                &-left {
                    width: 50%;

                    &_name {
                        font-size: 1rem;
                        margin-bottom: 0.5rem;
                    }

                    &_tel,
                    &_address {
                        font-size: 0.8rem;
                        color: rgba(255, 255, 255, 0.7);
                        margin-bottom: 0.5rem;
                    }

                    &_address {
                        margin-bottom: 2rem;
                    }

                }

                &-right {
                    width: 50%;
                    &_item {
                        width: 50%;
                        font-size: 0.8rem;

                        &:nth-of-type(1) {
                            margin-right: 0.5rem;
                        }

                        img {
                            width: 80%;
                            margin-bottom: 0.5rem;
                        }
                    }
                }

                &-icons {
                    display: flex;
                    align-items: center;

                    img {
                        margin-right: 1rem;
                        width: 10%;
                    }
                }

                &-copy {
                    height: auto;
                    line-height: normal;
                    padding: 0.7rem 0 0 0;
                    width: 100%;
                    border-top: 1px solid rgba(255, 255, 255, 0.1);
                    position: relative;
                    left: 0;
                    bottom: 0;
                    text-align: center;
                    font-size: 12px;
                    color: rgba(255, 255, 255, 0.3);
                }
            }
        }
    }

    @media only screen and (max-width: 749px) {
        .layout {
            &-footer {
                &-left {
                    &_name {
                        font-size: 1.3rem;
                    }

                    &_tel,
                    &_address {
                        font-size: 1rem;
                        margin-bottom: .3rem;
                    }
                }
            }
        }
    }
</style>
