<template>
  <div class="layout">
    <LayoutHeader />
    <div class="layout-main">
      <router-view></router-view>
    </div>
    <LayoutFooter />
  </div>
</template>

<script>
import LayoutFooter from "./layoutFooter.vue";
import LayoutHeader from "./layoutHeader.vue";
export default {
  name: "layout",
  components: {
    LayoutFooter,
    LayoutHeader,
  },
};
</script>

<style scoped lang="scss">
.layout {
  &-main {
  }
}
</style>
